const config = {
  env: 'local',

  // apiUrl: "http://localhost:5505/admin/",
  // apiUrl: " http://3.6.245.153:5566/admin/",
  apiUrl: "https://api.thehelpy.com/admin/",
  //  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/"

}

export default config;
