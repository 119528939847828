



const Loader = () => {
    return (
        <div>
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.946"
                    height="40.091"
                    viewBox="0 0 85.946 80.091"
                    style={{
                        animation: 'twinkling 1.5s infinite',
                        fill: '#4caf50'
                    }}
                >
                    <g id="icon" transform="translate(0 0.592)">
                        <g id="Path_25734" data-name="Path 25734" transform="translate(-1 -62.045)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M42.955,93.822c11.838,0,16.84-3.341,24.628-6.876,7.4-3.36,19.363-3.8,19.363,7.755,0,23.4-20.6,46.842-43.991,46.842S1,118.1,1,94.7c0-10.622,9.2-10.294,15.771-7.615C24.429,90.211,30.18,93.822,42.955,93.822Z" stroke="none" />
                            <path d="M 70.95811462402344 101.9266967773438 L 70.74925231933594 102.0244369506836 C 64.04325103759766 105.2106170654297 56.44248580932617 108.8219375610352 42.95454025268555 108.8219375610352 C 31.11276245117188 108.8219375610352 23.42520904541016 106.1996994018555 17.34028625488281 103.6482391357422 C 20.83539581298828 115.5767517089844 30.80409240722656 126.5439071655273 42.95454025268555 126.5439071655273 C 55.10243225097656 126.5439071655273 67.44424438476562 115.0436706542969 70.95811462402344 101.9266967773438 M 76.74811553955078 84.87454223632812 C 82.147705078125 84.87429809570312 86.94569396972656 87.38066101074219 86.94569396972656 94.70149230957031 C 86.94569396972656 118.097282409668 66.35035705566406 141.5439147949219 42.95454025268555 141.5439147949219 C 19.55872344970703 141.5439147949219 0.9999847412109375 118.097282409668 0.9999847412109375 94.70149230957031 C 0.9999847412109375 84.07975769042969 10.20443725585938 84.4075927734375 16.77080535888672 87.08683776855469 C 24.42945861816406 90.21127319335938 30.18043518066406 93.82192993164062 42.95454025268555 93.82192993164062 C 54.79282760620117 93.82192993164062 59.79435729980469 90.48124694824219 67.58269500732422 86.94615173339844 C 70.29637145996094 85.71432495117188 73.62324523925781 84.87467956542969 76.74811553955078 84.87454223632812 Z" stroke="none" fill="#4c8ccc" />
                        </g>
                        <ellipse id="Ellipse_948" data-name="Ellipse 948" cx="8" cy="8.5" rx="8" ry="8.5" transform="translate(68.996 -0.592)" fill="#4c8ccc" />
                        <ellipse id="Ellipse_949" data-name="Ellipse 949" cx="8" cy="8.5" rx="8" ry="8.5" transform="translate(0.996 -0.592)" fill="#4c8ccc" />
                    </g>
                </svg>
            </div>
            <style>
                {`
            @keyframes twinkling {
              0% { opacity: 0.5; transform: scale(0.9); }
              50% { opacity: 1; transform: scale(1); }
              100% { opacity: 0.5; transform: scale(0.9); }
            }
          `}
            </style>
        </div>
    );
};

export default Loader;



